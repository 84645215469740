/**
 * Site header
 */
 .site-header {
   // border-top: 5px solid $grey-color-dark;
    // border-top: 3px solid #E8472B; //#ebb0d7;
    //border-bottom: 1px solid #da6eb6;
    min-height: $spacing-unit * 1.865;
    //background-color: #ebb0d7;
    background-color: #EFF2F8; // #f8f9fa !important;
   // border-top: 1px solid #dee2e6 !important;
    border-bottom: 1px solid #dee2e6 !important;
    border-left: 1px solid #dee2e6 !important;
    border-right: 1px solid #dee2e6 !important;
   // border-bottom-right-radius: .25rem !important;
   // border-bottom-left-radius: .25rem !important;
    //position: relative !important;
    //margin-top: 5px;
    
    //max-width: 860px;
    //width:860px;
    //margin-right: auto;
    //margin-left: auto;
    //margin: 0 auto;
    //background-color: #d0db61;
  
    // Positioning context for the mobile navigation icon
    position: relative;
  }
  
  .site-title {
    @include relative-font-size(1.625);
    
    font-weight: 500;
    line-height: $base-line-height * $base-font-size * 2.25;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;
  
  
  @include media-query($on-palm) {
    padding-right: 45px;
  }
  
    &,
    &:visited {
      color: $grey-color-dark;
    }
  }

  // custom for masthead

  .site-masthead { 
    //display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-bottom: 0;
    float: left;
  
    
    height: $base-line-height * $base-font-size * 2.5;
    padding-top: $spacing-unit / 6;
    padding-bottom: $spacing-unit / 6;
    //opacity: 0.8;
    }
  
  .site-masthead:hover { 
  //background: url("/images/yinkared.png");
 opacity: 0.5;
 // background-color: red;
  }
  
  .site-nav {
    float: right;
    line-height: $base-line-height * $base-font-size * 2.5;
    text-transform: lowercase;
      

  
  
    .nav-trigger {
        display: none;
    }
  
    .menu-icon {
      display: none;
    }
  
    .page-link {
      color: $text-color;
      line-height: $base-line-height;
  
      // Gaps between nav items, but not on the last one
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  
    @include media-query($on-palm) {
      position: absolute;
      top: 19px;
      right: $spacing-unit / 2;
      background-color: $background-color;
      border: 1px solid $grey-color-light;
      
      border-radius: 5px;
      text-align: right;
  
      label[for="nav-trigger"] {
        display: block;
        float: right;
        width: 36px;
        height: 36px;
        z-index: 2;
        cursor: pointer;
        
      }
  
      .menu-icon {
        display: block;
        float: right;
        width: 36px;
        height: 26px;
        line-height: 0;
        padding-top: 10px;
       
        text-align: center;
  
        > svg {
          fill: $grey-color-dark;
        }
      }
  
      input ~ .trigger {
        clear: both;
        display: none;
      }
  
      input:checked ~ .trigger {
        display: block;
        padding-bottom: 5px;
      }
  
      .page-link {
        display: block;
        padding: 5px 10px;
  
        &:not(:last-child) {
          margin-right: 0;
        }
        margin-left: 20px;
      }
    }
  }
  
  
  
  
  .site-nav a:hover {
//color: #6c6b6b;
    transition: color 0.5s;
    text-decoration: none !important;
    border-color: #888;
	border-width: 0 0 1px 0;
	border-style: none none solid none;
	padding: 3px 0px 2px 0px;
	background-color: inherit;
	opacity: 0.5;
    }
  
  
  
  
  /**
   * Site footer
   */
  .site-footer {
    border-top: 1px solid $grey-color-light;
    padding: $spacing-unit 0;
  }
  
  .footer-heading {
    @include relative-font-size(1.125);
    margin-bottom: $spacing-unit / 2;
  }
  
  .contact-list,
  .social-media-list {
    list-style: none;
    margin-left: 0;
  }
  
  .footer-col-wrapper {
    @include relative-font-size(0.9375);
    color: $grey-color;
    margin-left: -$spacing-unit / 2;
    @extend %clearfix;
  }
  
  .footer-col {
    float: left;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
  }
  
  .footer-col-1 {
    width: -webkit-calc(35% - (#{$spacing-unit} / 2));
    width:         calc(35% - (#{$spacing-unit} / 2));
  }
  
  .footer-col-2 {
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width:         calc(20% - (#{$spacing-unit} / 2));
  }
  
  .footer-col-3 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width:         calc(45% - (#{$spacing-unit} / 2));
  }
  
  @include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
      width: -webkit-calc(50% - (#{$spacing-unit} / 2));
      width:         calc(50% - (#{$spacing-unit} / 2));
    }
  
    .footer-col-3 {
      width: -webkit-calc(100% - (#{$spacing-unit} / 2));
      width:         calc(100% - (#{$spacing-unit} / 2));
    }
  }
  
  @include media-query($on-palm) {
    .footer-col {
      float: none;
      width: -webkit-calc(100% - (#{$spacing-unit} / 2));
      width:         calc(100% - (#{$spacing-unit} / 2));
    }
  }
  
  
  
  /**
   * Page content
   */
  .page-content {
    padding: $spacing-unit 0;
    flex: 1;
  }
  
  .page-heading {
    @include relative-font-size(2);
  }
  
  .post-list-heading {
    @include relative-font-size(1.75);
  }
  
  .post-list {
    margin-left: 0;
    list-style: none;
  
    > li {
      margin-bottom: $spacing-unit;
    }
  }
  
  
  .homepost-list {
    margin-left: 0;
    list-style: none;
  
    > li {
      margin-bottom: 40px;
    }
  }
  
  .post-meta {
    font-size: $small-font-size;
    color: $grey-color;
  }
  
  .post-link {
    display: block;
    @include relative-font-size(1.5);
    
  }
  
  
  .post-linkpost {
    display: block;
    @include relative-font-size(1.5);
  // text-decoration: underline !important 
   
    }
  
      .post-linkpost::after{
    content: "  \279A\00FE0E "; //↗ \2934\00FE0E
    display:inline-block;
     // font-weight: 100;
      // font-size: 12;
      padding-left: 7px; 
      vertical-align: top;
      color: #dee2e6;     
}
  
   .post-linkpost::before{
    content: "\2937\00FE0E "; //⮑ ⤷
    //font-size: 120%;
    display:inline-block;
      font-weight: 300;
      padding-left: 5px;  
          
 }
  
  
  /**
   * Posts
   */
  .post-header {
    margin-bottom: $spacing-unit;
  }
  
  .post-title {
    @include relative-font-size(2.625);
    letter-spacing: -1px;
    line-height: 1;
  
    @include media-query($on-laptop) {
      @include relative-font-size(2.25);
    }
  }
  
  .post-excerpts { 
      color: lighten(#5f5e5e, 5%);
    }
    
   
 //READMORE LINK   
    
     .readmore { 
      font-size: 80%;
      font-weight: 300;       
      display: inline;
      float: right;
      margin-top: -1ex;  
      
    }
       
    .readmore a:hover {
    text-decoration: none !important;
    }
    
    .readmore::after{
    content: " \2192\00FE0E   "; //⟶→
    display:inline-block;
      font-weight: 300;
      padding-left: 4px;      
}

//INFINITY

     .infinity { 
      //font-size: 75%;
      font-weight: 300;       
      display: inline;
      float: right;
      margin-top: -2ex;  
    }
       
    .infinity a:hover {
    text-decoration: none !important;
    }
    
        


//ENDMARK start

 	.endmark {
 	float: right;
 	margin-top: -2ex;
 	color: #ebb0d7;
 	font-size: 75%;
 	//color: #5f5e5e;
 	}
    
    
  //category tags
    
       .cat-tag { 
      //color: #5f5e5e;
      font-size: 75%;
      font-weight: 300;
      //font-style: italic;
      display: inline;
      
      
      //color: inherit;
      
    }
    
    .link-arrow {
      color: #dee2e6;
      font-size: 80%;
      }

  .post-content {
    margin-bottom: $spacing-unit;
    color:lighten(#5f5e5e, 5%);
    
    h1, h2, h3 { margin-top: $spacing-unit  }
    h4, h5, h6 { margin-top: $spacing-unit }
  
    h2 {
      @include relative-font-size(2);
  
      @include media-query($on-laptop) {
        @include relative-font-size(1.75);
      }
    }
  
    h3 {
      @include relative-font-size(1.625);
  
      @include media-query($on-laptop) {
        @include relative-font-size(1.375);
      }
    }
  
  h4 {
    @include relative-font-size(1.25);
  }

  h5 {
    @include relative-font-size(1.125);
  }
  h6 {
    @include relative-font-size(1.0625);
  }
   
  }
  
  
  
  /**
 * Pagination navbar
 */
.pagination {
 padding-top: 30px;

 text-align: center;
  margin-bottom: $spacing-unit;
  @extend .social-media-list;
  li {
    a, div {
      min-width: 41px;
      text-align: center;
      box-sizing: border-box;
    }
    div {
      display: block;
      padding: $spacing-unit / 4;
      border: 1px solid transparent;

      &.pager-edge {
        color: $border-color-01;
        border: 1px dashed;
      }
    }
  }
}

.page_number, .next, .previous{
 background-color: #EFF2F8;
 padding: 10px;
}



/**
 * Grid helpers
 */
@media screen and (min-width: $on-large) {
  .one-half {
    width: calc(50% - (#{$spacing-unit} / 2));
  }
}