@charset "utf-8";

// Define defaults for each variable.

$base-font-family: 'Inter', 'Open Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$code-font-family: "Menlo", "Inconsolata", "Consolas", "Roboto Mono", "Ubuntu Mono", "Liberation Mono", "Courier New", monospace;
$base-font-size:   18px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$text-color:       #111 !default;
$background-color: #fdfdfd !default;
$code-background-color: #eeeeff !default;

//$brand-color:      #E8472B !default;
$brand-color:      #da6eb6 !default;


$brand-color-light:     lighten($brand-color, 40%) !default;
$brand-color-dark:      darken($brand-color, 25%) !default;

$site-title-color:      $brand-color-dark !default;

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;
// $green-band


$link-base-color:       #2a7ae2 !default;
$link-visited-color:    darken($link-base-color, 15%) !default;
$link-hover-color:      $text-color !default;

$border-color-01:       $brand-color-light !default;
$border-color-02:       lighten($brand-color, 35%) !default;
$border-color-03:       $brand-color-dark !default;

$table-text-color:      lighten($text-color, 18%) !default;
$table-zebra-color:     lighten($brand-color, 46%) !default;
$table-header-bg-color: lighten($brand-color, 43%) !default;
$table-header-border:   lighten($brand-color, 37%) !default;
$table-border-color:    $border-color-01 !default;



$table-text-align: left !default;

// Width of the content area
$content-width:    800px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

$on-medium:        $on-palm !default;
$on-large:         $on-laptop !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}


// Syntax highlighting styles should be adjusted appropriately for every "skin"
// ----------------------------------------------------------------------------

.highlight {
  .c     { color: #998; font-style: italic } // Comment
  .err   { color: #a61717; background-color: #e3d2d2 } // Error
  .k     { font-weight: bold } // Keyword
  .o     { font-weight: bold } // Operator
  .cm    { color: #998; font-style: italic } // Comment.Multiline
  .cp    { color: #999; font-weight: bold } // Comment.Preproc
  .c1    { color: #998; font-style: italic } // Comment.Single
  .cs    { color: #999; font-weight: bold; font-style: italic } // Comment.Special
  .gd    { color: #000; background-color: #fdd } // Generic.Deleted
  .gd .x { color: #000; background-color: #faa } // Generic.Deleted.Specific
  .ge    { font-style: italic } // Generic.Emph
  .gr    { color: #a00 } // Generic.Error
  .gh    { color: #999 } // Generic.Heading
  .gi    { color: #000; background-color: #dfd } // Generic.Inserted
  .gi .x { color: #000; background-color: #afa } // Generic.Inserted.Specific
  .go    { color: #888 } // Generic.Output
  .gp    { color: #555 } // Generic.Prompt
  .gs    { font-weight: bold } // Generic.Strong
  .gu    { color: #aaa } // Generic.Subheading
  .gt    { color: #a00 } // Generic.Traceback
  .kc    { font-weight: bold } // Keyword.Constant
  .kd    { font-weight: bold } // Keyword.Declaration
  .kp    { font-weight: bold } // Keyword.Pseudo
  .kr    { font-weight: bold } // Keyword.Reserved
  .kt    { color: #458; font-weight: bold } // Keyword.Type
  .m     { color: #099 } // Literal.Number
  .s     { color: #d14 } // Literal.String
  .na    { color: #008080 } // Name.Attribute
  .nb    { color: #0086B3 } // Name.Builtin
  .nc    { color: #458; font-weight: bold } // Name.Class
  .no    { color: #008080 } // Name.Constant
  .ni    { color: #800080 } // Name.Entity
  .ne    { color: #900; font-weight: bold } // Name.Exception
  .nf    { color: #900; font-weight: bold } // Name.Function
  .nn    { color: #555 } // Name.Namespace
  .nt    { color: #000080 } // Name.Tag
  .nv    { color: #008080 } // Name.Variable
  .ow    { font-weight: bold } // Operator.Word
  .w     { color: #bbb } // Text.Whitespace
  .mf    { color: #099 } // Literal.Number.Float
  .mh    { color: #099 } // Literal.Number.Hex
  .mi    { color: #099 } // Literal.Number.Integer
  .mo    { color: #099 } // Literal.Number.Oct
  .sb    { color: #d14 } // Literal.String.Backtick
  .sc    { color: #d14 } // Literal.String.Char
  .sd    { color: #d14 } // Literal.String.Doc
  .s2    { color: #d14 } // Literal.String.Double
  .se    { color: #d14 } // Literal.String.Escape
  .sh    { color: #d14 } // Literal.String.Heredoc
  .si    { color: #d14 } // Literal.String.Interpol
  .sx    { color: #d14 } // Literal.String.Other
  .sr    { color: #009926 } // Literal.String.Regex
  .s1    { color: #d14 } // Literal.String.Single
  .ss    { color: #990073 } // Literal.String.Symbol
  .bp    { color: #999 } // Name.Builtin.Pseudo
  .vc    { color: #008080 } // Name.Variable.Class
  .vg    { color: #008080 } // Name.Variable.Global
  .vi    { color: #008080 } // Name.Variable.Instance
  .il    { color: #099 } // Literal.Number.Integer.Long
}



// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/syntax-highlighting"
;