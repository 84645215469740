/**
 * Reset some basic elements
 */
 body, h1, h2, h3, h4, h5, h6,
 p, blockquote, pre, hr,
 dl, dd, ol, ul, figure {
   margin: 0;
   padding: 0;
 }
 
 
 
 /**
  * Basic styling
  */
 body {
   font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
   color: $text-color;
   //color: #5f5e5e;
   background-color: $background-color;
   -webkit-text-size-adjust: 100%;
   -webkit-font-feature-settings: "kern" 1;
      -moz-font-feature-settings: "kern" 1;
        -o-font-feature-settings: "kern" 1;
           font-feature-settings: "kern" 1;
   font-kerning: normal;
   display: flex;
   min-height: 100vh;
   flex-direction: column;
   overflow-wrap: break-word;
 }
 
 
 
 /**
  * Set `margin-bottom` to maintain vertical rhythm
  */
 h1, h2, h3, h4, h5, h6,
 p, blockquote, pre,
 ul, ol, dl, figure,
 %vertical-rhythm {
   margin-bottom: $spacing-unit / 2;
 }
 
 hr {
  margin-top: $spacing-unit;
  margin-bottom: $spacing-unit;
}


hr.slender {
    border: 0;
    height: 1px;
    color: red;
    margin-top: 3rem;
    margin-bottom: 2rem;
    background-image: -webkit-linear-gradient(left, transparent, rgba(0, 0, 0, 0.1), transparent);
    background-image: -moz-linear-gradient(left, transparent, rgba(0, 0, 0, 0.1), transparent);
   background-image: -ms-linear-gradient(left, transparent, rgba(0, 0, 0, 0.1), transparent);
    background-image: -o-linear-gradient(left, transparent, rgba(0, 0, 0, 0.1), transparent);
}

 
 /**
  * `main` element
  */
 main {
   display: block; /* Default value of `display` of `main` element is 'inline' in IE 11. */
 }
 
 
 
 /**
  * Images
  */
 img {
   max-width: 100%;
   vertical-align: middle;
 }
 
 /* To center images */
.center {
    text-align: center;
}

///////////////



@media only screen and (max-width: 600px) {

	img.yinka {
	width: 100%;
	height: auto;
	}

    
 


}





/////////////
 
 
 /**
  * Figures
  */
 figure > img {
   display: block;
 }
 
 figcaption {
   font-size: $small-font-size;
 }
 
 
 
 /**
  * Lists
  */
 ul, ol {
   margin-left: $spacing-unit;
 }
 
 li {
   > ul,
   > ol {
     margin-bottom: 0;
   }
 }
 
 
 
 /**
  * Headings
  */
 h1, h2, h3, h4, h5, h6 {
   font-weight: $base-font-weight;
 }
 
 
 
 /**
  * Links
  */
 a {
   color: $brand-color;
   text-decoration: none;
 
   &:visited {
     color: darken($brand-color, 15%);
   }
 
   &:hover {
     //color: $text-color;
     color: #5f5e5e;
     //text-decoration: underline; 
     text-decoration: none !important;
   
      
   }
 
   .social-media-list &:hover {
     text-decoration: none;
 
     .username {
       text-decoration: underline;
     }
   }
 }
 
 
 /**
  * Blockquotes
  */
// blockquote {
   
//   color: $grey-color;
//  border-left: 2px solid  $grey-color-light;
//   padding-left: $spacing-unit / 2;
//  @include relative-font-size(1.1);
//   letter-spacing: -1px;
  //font-style: italic;
//  font-weight: 300;
 
//   > :last-child {
 //  margin-bottom: 0;
//   }
   
//   i, em {
//    font-style: normal;
//  }
   
// }
 
 
 blockquote {
  background: #f5f5f5;
  border-left: .3rem solid #dce6f0;
  margin: 1.5rem .625rem;
  padding: 0.5rem .625rem;
  quotes: "\201C""\201D""\2018""\2019";

  p {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
}
 
 
 

 
 /**
  * Code formatting
  */
 pre,
 code {
   font-family: $code-font-family;
   font-size: 0.9375em;
   //@include relative-font-size(0.9375);
  // border: 1px solid $grey-color-light;
    border: 1px solid $border-color-01;
   border-radius: 3px;
   //background-color: #eef;
   background-color: $code-background-color;
 }
 
 code {
   padding: 1px 5px;
 }
 
 pre {
   padding: 8px 12px;
   overflow-x: auto;
 
   > code {
     border: 0;
     padding-right: 0;
     padding-left: 0;
   }
 }
 
 
 .highlight {
  border-radius: 3px;
  background: $code-background-color;
  @extend %vertical-rhythm;

  .highlighter-rouge & {
    background: $code-background-color;
  }
}
 
 /**
  * Wrapper
  */
// .wrapper {
  // max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
   //max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
  // margin-right: auto;
  // margin-left: auto;
  // padding-right: $spacing-unit;
  // padding-left: $spacing-unit;
  // @extend %clearfix;
 
  // @include media-query($on-laptop) {
  //   max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
   //  max-width:         calc(#{$content-width} - (#{$spacing-unit}));
  //   padding-right: $spacing-unit / 2;
   //  padding-left: $spacing-unit / 2;
 //  }
 //}
 
 
 /**
 * Wrapper
 */
.wrapper {
  max-width: calc(#{$content-width} - (#{$spacing-unit}));
  margin-right: auto;
  margin-left: auto;
  padding-right: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
  @extend %clearfix;

  @media screen and (min-width: $on-large) {
    max-width: calc(#{$content-width} - (#{$spacing-unit} * 2));
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
  }
}
 
 
 
 /**
  * Clearfix
  */
 %clearfix:after {
   content: "";
   display: table;
   clear: both;
 }
 
 
 
 /**
  * Icons
  */
  
  .orange {
  color: #f66a0a;
}

.grey {
  color: #828282;
}
 
 .svg-icon {
     width: 16px;
     height: 16px;
     display: inline-block;
     fill: #{$grey-color};
     padding-right: 5px;
     vertical-align: text-top;
 }
 
 .social-media-list {
   li + li {
     padding-top: 5px;
   }
 }
 
 
 
 /**
  * Tables
  */
 table {
   margin-bottom: $spacing-unit;
   width: 100%;
   text-align: $table-text-align;
   color: lighten($text-color, 18%); //$table-text-color;
   border-collapse: collapse;
   border: 1px solid $grey-color-light; //$table-border-color;
   tr {
     &:nth-child(even) {
       background-color: lighten($grey-color-light, 6%); //$table-zebra-color;
     }
   }
   th, td {
     padding: ($spacing-unit / 3) ($spacing-unit / 2);
   }
   th {
   
   //background-color: $table-header-bg-color;
   // border: 1px solid $table-header-border;
     background-color: lighten($grey-color-light, 3%);
     border: 1px solid darken($grey-color-light, 4%);
     border-bottom-color: darken($grey-color-light, 12%);
   }
   td {
     border: 1px solid $grey-color-light; //$table-border-color;
   }
   
   @include media-query($on-laptop) {
    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;
  }
   
 }
 
 
 //alert
 
.alert {
    background-color: #fafafa !important;
    border-color: #dedede !important;
    color: black;
}
 
 
 
 //reading list
 
 last-update {
  font-size: 16px;
  color: #777;
  margin: 2rem 0;
}

.number {
  font-size: 16px;
  color: #777;
}

.year-container {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 2rem;
  align-items: flex-start;
  
  @media (max-width: 800px) {
    display: block;
  }
  
  .books {
    ul.reading-list {
      line-height: 1.7;
    }
    .author {
      font-size: 16px;
      color: #555;
    }
    .star {
      margin-left: 3px;
      color: #F76B48;
      font-size: 16px;
    }
    a {
      color: $brand-color;
    }
  }
  
}

.alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px
}

.alert h4 {
    margin-top: 0;
    color: inherit
}

.alert .alert-link {
    font-weight: 700
}

.alert>p,
.alert>ul {
    margin-bottom: 0
}

.alert>p+p {
    margin-top: 5px
}

.alert-dismissable,
.alert-dismissible {
    padding-right: 35px
}

.alert-dismissable .close,
.alert-dismissible .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: inherit
}

.alert-success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6
}

.alert-success hr {
    border-top-color: #c9e2b3
}

.alert-success .alert-link {
    color: #2b542c
}

.alert-info {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1
}

.alert-info hr {
    border-top-color: #a6e1ec
}

.alert-info .alert-link {
    color: #245269
}

.alert-warning {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc
}

.alert-warning hr {
    border-top-color: #f7e1b5
}

.alert-warning .alert-link {
    color: #66512c
}

.alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1
}

.alert-danger hr {
    border-top-color: #e4b9c0
}

.alert-danger .alert-link {
    color: #843534
}

mark {
  background-color: #fff6ea;
  color: black;
}
 